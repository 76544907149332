


























































































import { computed, defineComponent, ref } from "@vue/composition-api";
import { AktivitasPemeriksaan } from "../models/aktivitasPemeriksaan";
import AktivitasPemeriksaanFM from "../models/aktivitasPemeriksaanForm";
import { Komponen } from "@/apps/penyakit/models/komponen";
import router from "@/router";
import useFormModel from "@/apps/core/modules/useFormModel";

/* Hanya create saja, tanpa updated, tidak perlu paramsId dari router */
export default defineComponent({
  name: "AktivitasPemeriksaanForm",
  props: { headerText: String },
  components: {
    DatePicker: () => import("@/apps/core/components/DatePicker.vue"),
    GenericAutocomplete: () =>
      import("@/apps/core/components/GenericAutocomplete.vue"),
    GenericSelect: () => import("@/apps/core/components/GenericSelect.vue"),
    KomponenModalForm: () => import("@/apps/penyakit/components/KomponenModalForm.vue"),
    ViewHeader: () => import("@/apps/core/components/ViewHeader.vue"),
  },
  setup() {
    const isPemeriksaanModalFormActive = ref(false);
    const namaKomponen = ref("");
    const form = new AktivitasPemeriksaanFM();
    const paramsId = router.currentRoute.params.id;
    const composition = useFormModel(form, paramsId);
    const aktivitasPemeriksaan = composition.instance as AktivitasPemeriksaan;
    const aktivitasId = computed(() => {
      return router.currentRoute.params.aktivitasId ?? aktivitasPemeriksaan.aktivitas?.id;
    });

    const setPemeriksaan = (pemeriksaan?: Komponen) => {
      aktivitasPemeriksaan.pemeriksaan = pemeriksaan ?? aktivitasPemeriksaan.pemeriksaan;
      composition.validate("pemeriksaan");
      isPemeriksaanModalFormActive.value = false;
    };

    return {
      // Data
      isPemeriksaanModalFormActive,
      aktivitasPemeriksaan,
      aktivitasId,
      namaKomponen,

      // Composition
      ...composition,

      // Method
      setPemeriksaan
    };
  },
});
